@font-face {
    font-family: 'itc-avant-garde-gothic-pro';
    src: url('../fonts/subset-ITCAvantGardeStd-Bk.woff2') format('woff2'),
        url('../fonts/subset-ITCAvantGardeStd-Bk.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'itc-avant-garde-gothic-pro';
    src: url('../fonts/subset-ITCAvantGardeStd-BkObl.woff2') format('woff2'),
        url('../fonts/subset-ITCAvantGardeStd-BkObl.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'itc-avant-garde-gothic-pro';
    src: url('../fonts/subset-ITCAvantGardeStd-Demi.woff2') format('woff2'),
        url('../fonts/subset-ITCAvantGardeStd-Demi.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'itc-avant-garde-gothic-pro';
    src: url('../fonts/subset-ITCAvantGardeStd-Bold.woff2') format('woff2'),
        url('../fonts/subset-ITCAvantGardeStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


// stylelint-disable value-keyword-case
$font__main: 'itc-avant-garde-gothic-pro', Helvetica, Arial, sans-serif;
$font__headline: 'itc-avant-garde-gothic-pro', Helvetica, Arial, sans-serif;