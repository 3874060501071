@font-face {
  font-family: itc-avant-garde-gothic-pro;
  src: url("subset-ITCAvantGardeStd-Bk.5d9e0239.woff2") format("woff2"), url("subset-ITCAvantGardeStd-Bk.8042a848.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: itc-avant-garde-gothic-pro;
  src: url("subset-ITCAvantGardeStd-BkObl.1def0178.woff2") format("woff2"), url("subset-ITCAvantGardeStd-BkObl.72bcce55.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: itc-avant-garde-gothic-pro;
  src: url("subset-ITCAvantGardeStd-Demi.a097e890.woff2") format("woff2"), url("subset-ITCAvantGardeStd-Demi.8d05c754.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: itc-avant-garde-gothic-pro;
  src: url("subset-ITCAvantGardeStd-Bold.3cd7a1d2.woff2") format("woff2"), url("subset-ITCAvantGardeStd-Bold.f96628f0.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html, body {
  scroll-behavior: smooth;
  color: #1c355e;
  hyphens: auto;
  margin: 0;
  padding: 0;
  font-family: itc-avant-garde-gothic-pro, Helvetica, Arial, sans-serif;
  font-weight: 400;
}

* {
  box-sizing: border-box;
}

@media (width >= 801px) {
  .transform-desktop {
    transform: var(--transform-desktop, none);
  }
}

header {
  z-index: 20;
  pointer-events: none;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
}

header .content {
  z-index: 2;
  flex-direction: row;
  justify-content: flex-end;
  padding: 2rem 0 0;
  display: flex;
  position: absolute;
}

.main-nav {
  color: #fff;
  opacity: 0;
  visibility: hidden;
  pointer-events: all;
  hyphens: none;
  background-color: #1c355e;
  border-radius: 1.5rem;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 2rem);
  max-width: 1500px;
  max-height: calc(100dvh - 2rem);
  margin: 1rem auto 0;
  padding: 3rem;
  transition: all .3s ease-out;
  display: flex;
  overflow: hidden auto;
  transform: translateY(-100px);
}

.main-nav--open {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.main-nav__toggle {
  appearance: none;
  cursor: pointer;
  color: #1c355e;
  pointer-events: all;
  background: #fff;
  border: 0;
  border-radius: 100%;
  outline: 0;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

.main-nav__toggle--open {
  color: #fff;
  background-color: #0000;
}

.main-nav__toggle__icon {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
  display: block;
  position: relative;
}

.main-nav__toggle__bar {
  will-change: transform, width;
  background: currentColor;
  border-radius: .0625em;
  width: 1em;
  height: .125em;
  transition: transform .3s ease-out, width .3s ease-out;
  display: block;
  position: absolute;
  top: .5em;
  left: .5em;
  transform: translateX(-.5em);
}

.main-nav__toggle__bar:first-child {
  transform: translateX(-.5em)translateY(-50%);
}

.main-nav__toggle--open .main-nav__toggle__bar:first-child {
  width: .6em;
  transform: translateX(-.3em)translateY(100%)rotate(-90deg);
}

.main-nav__toggle__bar:nth-child(2) {
  transform: translateX(-.5em)translateY(.25em);
}

.main-nav__toggle--open .main-nav__toggle__bar:nth-child(2) {
  width: .7em;
  transform: translateX(-.35em)translateY(.375em);
}

.main-nav__toggle__bar:nth-child(3) {
  transform-origin: .0625em;
  transform: translateX(-.5em)translateY(-.375em);
}

.main-nav__toggle--open .main-nav__toggle__bar:nth-child(3) {
  width: .7em;
  transform: translateX(-.49em)translateY(-.5em)rotate(45deg);
}

.main-nav__toggle__bar:nth-child(4) {
  transform-origin: calc(100% - .0625em);
  transform: translateX(-.5em)translateY(-.375em);
}

.main-nav__toggle--open .main-nav__toggle__bar:nth-child(4) {
  width: .7em;
  transform: translateX(-.21em)translateY(-.5em)rotate(-45deg);
}

.main-nav__toggle__bar:nth-child(5) {
  transform: translateX(-.5em)translateY(-.375em);
}

.main-nav__toggle--open .main-nav__toggle__bar:nth-child(5) {
  transform: translateX(-.5em)translateY(-.5em);
}

.main-nav__logo {
  filter: invert();
  width: 150px;
  margin-bottom: 3rem;
}

.main-nav ul {
  text-align: right;
  flex-direction: column;
  gap: .75em;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  list-style: none;
  display: flex;
}

@media (width >= 600px) {
  .main-nav ul {
    font-size: 4vw;
  }
}

@media (width >= 1400px) {
  .main-nav ul {
    font-size: 3.5rem;
  }
}

@media (width <= 500px) {
  .main-nav ul {
    text-align: center;
  }
}

.main-nav a {
  color: inherit;
  text-decoration: none;
  transition: opacity .3s ease-out;
}

.main-nav a:hover {
  opacity: .6;
}

.site-title {
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 4.5rem 0 3rem;
  display: flex;
}

.site-title img {
  width: auto;
  height: 100%;
}

.content {
  box-sizing: border-box;
  flex-direction: column;
  width: 80%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (width <= 500px) {
  .content {
    padding-left: 0;
    padding-right: 0;
  }
}

.content--v-padded {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.content--vb-padded {
  padding-bottom: 6rem;
}

@media (width <= 800px) {
  .content--vb-padded {
    padding-bottom: 3rem;
  }
}

.content--twocol {
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  gap: 3rem 15%;
  display: grid;
}

@media (width <= 800px) {
  .content--twocol {
    flex-direction: column;
    display: flex;
  }
}

.content--box {
  background-color: #f4f4f4;
  border-radius: 1rem;
  box-shadow: -1.5rem 0 #f4f4f4, 1.5rem 0 #f4f4f4;
}

.content--box.content--v-padded {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.section--hero {
  justify-content: center;
  align-items: center;
  height: 80vh;
  display: flex;
}

.section--hero .content {
  height: 100%;
}

.section--head {
  justify-content: center;
  align-items: center;
  height: 25rem;
  display: flex;
}

.section--head .content {
  height: 100%;
}

.section__title {
  margin: 0;
  font-size: 3.75rem;
  font-weight: 600;
}

@media (width <= 500px) {
  .section__title {
    font-size: 2rem;
  }

  .section__title br {
    display: none;
  }
}

.section__title--small {
  font-size: 2.5rem;
}

@media (width <= 500px) {
  .section__title--small {
    font-size: 1.75rem;
  }
}

.section__title__wrapper {
  z-index: 3;
  flex-direction: column;
  gap: .75rem;
  transition: all .3s ease-out;
  display: flex;
  position: relative;
}

.section__title__wrapper--pb {
  padding-bottom: 9rem;
}

.section__title__wrapper--hide {
  opacity: 0;
}

.section__subtitle {
  font-size: 1.625rem;
}

@media (width <= 500px) {
  .section__subtitle {
    font-size: 1.25rem;
  }
}

.section__text {
  line-height: 1.5rem;
}

.section__text a {
  color: inherit;
}

.clip {
  width: 100%;
  overflow: hidden;
}

.parallax {
  height: 100vh;
  position: relative;
}

@media (width <= 767px) {
  .parallax {
    height: auto;
    margin-bottom: 2rem;
  }
}

.parallax .content {
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  overflow: visible;
}

@media (width <= 767px) {
  .parallax .content {
    height: auto;
  }
}

.parallax__info {
  max-width: 300px;
  position: absolute;
  top: 75%;
  left: 2rem;
}

@media (width <= 767px) {
  .parallax__info {
    margin-top: 3rem;
    position: static;
  }
}

.parallax__layer {
  position: absolute;
  inset: 0;
}

@media (width <= 767px) {
  .parallax__layer {
    position: static;
  }
}

.parallax__layer img {
  border-radius: 1rem;
  display: flex;
  position: absolute;
  top: 50%;
  overflow: hidden;
}

@media (width <= 767px) {
  .parallax__layer img {
    position: static;
    width: 100% !important;
    height: auto !important;
    transform: none !important;
  }
}

.parallax__layer:first-child img {
  height: 60%;
  max-height: 600px;
  left: 0%;
  transform: translateY(-50%);
}

.parallax__layer:nth-child(2) img {
  height: 60%;
  max-height: 600px;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

@media (width <= 900px) {
  .parallax__layer:nth-child(2) {
    display: none;
  }
}

.parallax__layer:nth-child(3) img {
  height: 70%;
  max-height: 700px;
  right: 0%;
  transform: translateY(-50%);
}

@media (width <= 767px) {
  .parallax__layer:nth-child(3) {
    display: none;
  }
}

.floating-bottle {
  z-index: 2;
  pointer-events: none;
  justify-content: center;
  width: 100%;
  height: 300vh;
  display: flex;
  position: relative;
}

.floating-bottle__text {
  opacity: 0;
  visibility: hidden;
  width: 100%;
  max-width: 300px;
  line-height: 1.5rem;
  transition: all .3s ease-out;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.floating-bottle__text--left {
  left: 0;
}

.floating-bottle__text--right {
  right: 0;
}

@media (aspect-ratio <= 1.5) {
  .floating-bottle__text {
    text-align: justify;
    width: auto;
    margin: 0 5.56%;
    inset: auto 0 3rem;
    transform: none;
  }
}

.floating-bottle__text--show {
  visibility: visible;
  opacity: 1;
}

.floating-bottle__image {
  width: 80%;
  max-width: 1000px;
  height: 80vh;
  position: sticky;
  top: 10vh;
  bottom: 10vh;
}

@media (aspect-ratio <= 1) {
  .floating-bottle__image {
    width: 90%;
  }
}

@media (aspect-ratio <= 1.5) {
  .floating-bottle__image {
    height: 90vh;
  }
}

.floating-bottle img {
  object-fit: contain;
  object-position: center bottom;
  transform-origin: bottom;
  width: 100%;
  height: 100%;
  display: block;
}

@media (aspect-ratio <= 1.5) {
  .floating-bottle img {
    object-position: center top;
    transform-origin: top;
  }
}

.selection {
  justify-content: space-around;
  gap: 1rem;
  padding-bottom: 5rem;
  display: flex;
}

.selection + .selection {
  margin-top: -1rem;
}

@media (width <= 1023px) {
  .selection {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .selection + .selection {
    margin-top: -4rem;
  }
}

.selection__item {
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  display: flex;
  overflow: hidden;
}

.selection__item img {
  width: 100%;
  height: auto;
}

@media (width >= 1024px) {
  .selection + .selection .selection__item img {
    width: calc(100% + 7rem);
    margin: 0 -3.5rem;
  }
}

.selection__item__title {
  text-align: center;
  margin: .75rem 0 0;
  font-size: 2rem;
  font-weight: bold;
}

.selection__item__subtitle {
  text-align: center;
  font-size: 1.5rem;
}

.selection__item__description {
  opacity: .5;
  hyphens: auto;
  width: 70%;
  max-width: 260px;
  margin-top: 1.5rem;
  margin-bottom: .75rem;
  font-style: italic;
  line-height: 1.5rem;
}

.selection__item__price {
  color: #fff;
  background-color: #1c355e;
  border-radius: .5rem;
  padding: .625rem 1rem .375rem;
  font-size: 1.125rem;
}

.selection__item__shipping {
  opacity: .5;
  margin-top: .375rem;
  font-size: .625rem;
  line-height: 1.5rem;
}

.drinks {
  scroll-snap-type: x mandatory;
  justify-content: flex-start;
  gap: 1rem;
  padding: 1rem;
  display: flex;
  position: relative;
  overflow: auto hidden;
}

.drinks__link {
  z-index: 3;
  display: block;
  position: absolute;
  inset: 0;
}

.drinks__modal {
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  background: #fffc;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  transition: all .3s ease-out;
  display: flex;
  position: fixed;
  inset: 0;
  overflow: hidden auto;
}

.drinks__modal--show {
  opacity: 1;
  visibility: visible;
}

@media (width <= 500px) {
  .drinks__modal {
    padding: 1rem;
  }
}

@media (width <= 800px) {
  .drinks__modal {
    align-items: flex-start;
  }
}

.drinks__modal__close {
  appearance: none;
  color: #1c355e;
  cursor: pointer;
  background-color: #fff;
  border: 0;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  font-family: sans-serif;
  font-size: 2rem;
  font-weight: 300;
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;
  transform: rotate(45deg);
}

.drinks__modal__content {
  flex-direction: row;
  gap: 1rem;
  max-width: 1000px;
  max-height: 600px;
  display: flex;
  position: relative;
}

@media (width <= 800px) {
  .drinks__modal__content {
    flex-direction: column;
    max-height: none;
  }
}

.drinks__modal__wrapper {
  color: #fff;
  background-color: #1c355e;
  border-radius: 1rem;
  flex-basis: 150%;
  padding: 3rem;
}

@media (width >= 800px) {
  .drinks__modal__wrapper {
    order: -1;
  }
}

@media (width <= 500px) {
  .drinks__modal__wrapper {
    padding: 2rem;
  }
}

.drinks__modal__image {
  border-radius: 1rem;
  align-items: stretch;
  min-height: 400px;
  display: flex;
  overflow: hidden;
}

.drinks__modal__image img {
  object-fit: cover;
  object-position: center top;
  width: 100%;
}

@media (width <= 400px) {
  .drinks__modal__image {
    height: 100vw;
    min-height: 0;
  }
}

.drinks__modal__title {
  hyphens: none;
  margin: 0 0 1.5rem;
  font-size: 3.75rem;
  font-weight: 600;
}

@media (width <= 500px) {
  .drinks__modal__title {
    font-size: 2.5rem;
  }
}

.drinks__modal__subtitle {
  font-size: 1.625rem;
}

@media (width <= 500px) {
  .drinks__modal__subtitle {
    font-size: 1.25rem;
  }
}

.drinks__modal__text {
  line-height: 1.5rem;
}

.drinks__item {
  aspect-ratio: .9;
  scroll-snap-align: center;
  border-radius: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
  height: 40vh;
  max-height: 600px;
  position: relative;
  overflow: hidden;
}

.drinks__item > img {
  object-fit: cover;
  object-position: center bottom;
  width: 100%;
  height: 100%;
}

.drinks__item__name {
  color: #fff;
  z-index: 1;
  opacity: 0;
  background-color: #ffffff8c;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  transition: all .3s ease-out;
  display: flex;
  position: absolute;
  inset: 0;
}

.drinks__item:hover .drinks__item__name {
  opacity: 1;
}

.form {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

.form a {
  color: inherit;
  transition: opacity .2s ease-out;
}

.form a:hover {
  opacity: .5;
}

@media (width <= 800px) {
  .form {
    margin-top: 0;
  }
}

.form__label {
  border-bottom: 1px solid;
  flex-direction: column-reverse;
  padding-top: .75rem;
  display: flex;
}

.form__label--inline {
  border-bottom: 1px solid #0000;
  grid-template-columns: 60px auto;
  gap: 1rem;
  display: grid;
  position: relative;
}

.form__label--inline:after {
  content: "";
  background-color: currentColor;
  width: 60px;
  height: 1px;
  position: absolute;
  top: 100%;
  left: 0;
}

.form__label--checkbox {
  border: none;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 1rem;
  display: grid;
  position: relative;
}

.form__label__text {
  transform-origin: 0 100%;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.25rem;
  transition: all .3s ease-out;
}

.form__label--checkbox .form__label__text {
  font-size: 1rem;
}

.form__label input, .form__label textarea {
  appearance: none;
  color: currentColor;
  background: none;
  border: 0;
  width: 100%;
  font-family: HelveticaNow, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.form__label input:focus, .form__label textarea:focus {
  outline: 0;
}

.form__label input:focus + *, .form__label textarea:focus + * {
  transform: scale(.875)translateY(-50%);
}

.form__label--inline.form__label input:focus + *, .form__label textarea:focus + *, .form__label--checkbox.form__label input:focus + *, .form__label textarea:focus + * {
  transform: none;
}

.form__label input[type="checkbox"], .form__label textarea[type="checkbox"] {
  cursor: pointer;
  border: 1px solid;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
}

.form__label input[type="checkbox"]:checked:after, .form__label textarea[type="checkbox"]:checked:after {
  content: "";
  background-color: currentColor;
  width: 1rem;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.form__label input[type="checkbox"]:checked:before, .form__label textarea[type="checkbox"]:checked:before {
  content: "";
  background-color: currentColor;
  width: 1rem;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.form__label textarea {
  resize: vertical;
}

.form input[type="submit"] {
  appearance: none;
  color: #fff;
  cursor: pointer;
  background: #1c355e;
  border: 0;
  border-radius: .5rem;
  width: auto;
  margin-left: auto;
  padding: .625rem 1rem .375rem;
  font-family: itc-avant-garde-gothic-pro, Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.5rem;
}

.form__success {
  opacity: 0;
  color: #0c0;
  text-align: center;
  background: #efe;
  padding: 1.5rem 1rem;
  transition: all .3s ease-in-out;
  display: none;
}

.form__success--show {
  opacity: 1;
  display: block;
}

.form__error {
  opacity: 0;
  color: #c00;
  text-align: center;
  background: #fee;
  padding: 1.5rem 1rem;
  transition: all .3s ease-in-out;
  display: none;
}

.form__error--show {
  opacity: 1;
  display: block;
}

.form__submit {
  align-self: center;
  display: inline-block;
  position: relative;
}

.form__submit:after {
  content: "";
  opacity: 0;
  border: 4px solid #1c355e;
  border-top-color: #0000;
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  margin-top: -.5rem;
  transition: opacity .3s ease-in-out;
  animation: .5s linear infinite both progress;
  display: block;
  position: absolute;
  top: 50%;
  right: -2rem;
}

.form__submit--progress:after {
  opacity: 1;
}

.form__mandatory {
  text-align: center;
  align-self: center;
  margin-top: -.75rem;
}

.footer {
  color: #fff;
  background: #1c355e;
  margin-top: 6rem;
  padding: 3rem 0;
}

.footer .content {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

@media (width <= 600px) {
  .footer .content {
    text-align: center;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }
}

.footer a {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease-out;
}

.footer a:hover {
  opacity: .6;
}

.footer__menu {
  flex-direction: column;
  gap: .75rem;
  width: 200px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.footer__logo {
  align-items: center;
  height: auto;
  display: flex;
}

.footer__logo img {
  object-fit: cover;
  filter: invert();
  width: 150px;
  height: 110px;
}

.footer__address {
  text-align: right;
  width: 205px;
}
/*# sourceMappingURL=index.6cbc1cc5.css.map */
