@import "fonts";

$color__primary: #1C355E;

html, body {
    margin: 0;
    padding: 0;
    font-family: $font__main;
    scroll-behavior: smooth;
    color: $color__primary;
    font-weight: 400;
    hyphens: auto;
}

* {
    box-sizing: border-box;
}

.transform-desktop {
    @media(min-width: 801px) {
        transform: var(--transform-desktop, none);
    }
}

header {
    position: fixed;
    z-index: 20;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;

    .content {
        position: absolute;
        z-index: 2;
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        padding: 2rem 0 0;
    }
}

.main-nav {
    background-color: $color__primary;
    color: #fff;
    border-radius: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    max-height: calc(100dvh - 2rem);
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 1500px;
    margin: 1rem auto 0;
    width: calc(100% - 2rem);
    transform: translateY(-100px);
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-out;
    pointer-events: all;
    hyphens: none;

    &--open {
        opacity: 1;
        visibility: visible;
        transform: none;
    }

    &__toggle {
        width: 4rem;
        height: 4rem;
        appearance: none;
        border: 0;
        background: transparent;
        position: relative;
        margin: 0;
        padding: 0;
        outline: 0;
        cursor: pointer;
        color: $color__primary;
        border-radius: 100%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: all;

        $open: '';
        &--open {
            $open: #{&};
            color: #fff;
            background-color: transparent;
        }

        &__icon {
            display: block;
            width: 2rem;
            height: 2rem;
            font-size: 2rem;
            position: relative;
        }

        &__bar {
            position: absolute;
            display: block;
            left: .5em;
            width: 1em;
            height: .125em;
            background: currentColor;
            border-radius: 0.0625em;
            transition: 
                transform .3s ease-out,
                width .3s ease-out;
            top: .5em;
            transform: translateX(-.5em);
            will-change: transform, width;

            &:nth-child(1) {
                transform: translateX(-.5em) translateY(-50%);
    
                #{$open} & {
                    transform: translateX(-.3em) translateY(100%) rotate(-90deg);
                    width: .6em;
                }
            }

            &:nth-child(2) {
                transform: translateX(-.5em) translateY(.25em);

                #{$open} & {
                    transform: translateX(-.35em) translateY(.375em);
                    width: .7em;
                }
            }

            &:nth-child(3) {
                transform: translateX(-.5em) translateY(-.375em);
                transform-origin: .0625em 50%;

                #{$open} & {
                    transform: translateX(-.49em) translateY(-.5em) rotate(45deg);
                    width: .7em;
                }
            }

            &:nth-child(4) {
                transform: translateX(-.5em) translateY(-.375em);
                transform-origin: calc(100% - .0625em) 50%;

                #{$open} & {
                    transform: translateX(-.21em) translateY(-.5em) rotate(-45deg);
                    width: .7em;
                }
            }

            &:nth-child(5) {
                transform: translateX(-.5em) translateY(-.375em);

                #{$open} & {
                    transform: translateX(-.5em) translateY(-.5em);
                }
            }
        }
    }

    &__logo {
        width: 150px;
        filter: #{'invert()'};
        margin-bottom: 3rem;
    }

    ul {
        margin: 0;
        padding: 0;
        font-size: 1.5rem;
        list-style: none;
        width: 100%;
        text-align: right;
        display: flex;
        flex-direction: column;
        gap: .75em;

        @media(min-width: 600px) {
            font-size: 4vw;
        }
        @media(min-width: 1400px) {
            font-size: 3.5rem;
        }

        @media(max-width: 500px) {
            text-align: center;
        }
    }

    a {
        color: inherit;
        text-decoration: none;
        transition: opacity .3s ease-out;

        &:hover {
            opacity: .6;
        }
    }
}

.site-title {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 4.5rem 0 3rem;
    margin: 0;

    img {
        height: 100%;
        width: auto;
    }
}

.content {
    max-width: 1500px;
    width: 80%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    padding: 0 2rem;
    overflow: hidden;
    box-sizing: border-box;

    @media(max-width: 500px) {
        padding-left: 0rem;
        padding-right: 0rem;
    }

    $vpadded: '';

    &--v-padded {
        $vpadded: #{&};
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    &--vb-padded {
        padding-bottom: 6rem;

        @media(max-width: 800px) {
            padding-bottom: 3rem;
        }
    }

    &--twocol {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 3rem 15%;
        align-items: flex-start;

        @media(max-width: 800px) {
            display: flex;
            flex-direction: column;
        }
    }

    &--box {
        background-color: #F4F4F4;
        border-radius: 1rem;
        box-shadow: -1.5rem 0 0 0 #f4f4f4, 1.5rem 0 0 0 #f4f4f4;

        &#{$vpadded} {
            padding-top: 4.5rem;
            padding-bottom: 4.5rem;
        }
    }
}

.section {
    &--hero {
        height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .content {
            height: 100%;
        }
    }

    &--head {
        height: 25rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .content {
            height: 100%;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 3.75rem;
        margin: 0;

        @media(max-width: 500px) {
            font-size: 2rem;

            br {
                display: none;
            }
        }

        &--small {
            font-size: 2.5rem;

            @media(max-width: 500px) {
                font-size: 1.75rem;
            }
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            gap: .75rem;
            position: relative;
            z-index: 3;
            transition: all .3s ease-out;

            &--pb {
                padding-bottom: 9rem;
            }

            &--hide {
                opacity: 0;
            }
        }
    }

    &__subtitle {
        font-size: 1.625rem;

        @media(max-width: 500px) {
            font-size: 1.25rem;
        }
    }

    &__text {
        line-height: 1.5rem;

        a {
            color: inherit;
        }
    }
}

.clip {
    overflow: hidden;
    width: 100%;
}

.parallax {
    position: relative;
    height: 100vh;

    @media(max-width: 767px) {
        height: auto;
        margin-bottom: 2rem;
    }

    .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        overflow: visible;
        position: relative;

        @media(max-width: 767px) {
            height: auto;
        }
    }

    &__info {
        position: absolute;
        top: 75%;
        left: 2rem;
        max-width: 300px;

        @media(max-width: 767px) {
            position: static;
            margin-top: 3rem;
        }
    }

    &__layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    
        @media(max-width: 767px) {
            position: static;
        }

        img {
            top: 50%;
            display: flex;
            border-radius: 1rem;
            overflow: hidden;
            position: absolute;

            @media(max-width: 767px) {
                position: static;
                transform: none !important;
                height: auto !important;
                width: 100% !important;
            }
        }

        &:nth-child(1){ 
            img {
                left: 0%;
                height: 60%;
                max-height: 600px;
                transform: translateY(-50%);
            }
        }

        &:nth-child(2) {
            img {
                left: 50%;
                height: 60%;
                max-height: 600px;
                transform: translateX(-50%) translateY(-50%);
            }

            @media(max-width: 900px) {
                display: none;
            }
        }

        &:nth-child(3) {
            img {
                right: 0%;
                height: 70%;
                max-height: 700px;
                transform: translateY(-50%);
            }

            @media(max-width: 767px) {
                display: none;
            }
        }
    }
}

.floating-bottle {
    position: relative;
    height: 300vh;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 2;
    pointer-events: none;
    
    &__text {
        position: absolute;
        top: 50%;
        max-width: 300px;
        width: 100%;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease-out;
        line-height: 1.5rem;

        &--left {
            left: 0;
        }

        &--right {
            right: 0;
        }

        @media (max-aspect-ratio: 1.5) {
            top: auto;
            bottom: 3rem;
            left: 0;
            right: 0;
            text-align: justify;
            transform: none;
            margin: 0 5.56%;
            width: auto;
        }

        &--show {
            visibility: visible;
            opacity: 1;
        }
    }

    &__image {
        width: 80%;
        height: 80vh;
        max-width: 1000px;
        position: sticky;
        top: 10vh;
        bottom: 10vh;

        @media (max-aspect-ratio: 1) {
            width: 90%;
        }

        @media (max-aspect-ratio: 1.5) {
            height: 90vh;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center bottom;
        transform-origin: center bottom;
        display: block;

        @media (max-aspect-ratio: 1.5) {
            object-position: center top;
            transform-origin: center top;
        }
    }
}

.selection {
    display: flex;
    gap: 1rem;
    justify-content: space-around;
    padding-bottom: 5rem;

    & + & {
        margin-top: -1rem;
    }

    @media(max-width: 1023px) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        
        & + & {
            margin-top: -4rem;
        }
    }

    &__item {
        max-width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;

            .selection + .selection & {
                @media(min-width: 1024px) {
                    width: calc(100% + 7rem);
                    margin: 0 -3.5rem;
                }
            }
        }

        &__title {
            text-align: center;
            font-size: 2rem;
            margin: .75rem 0 0;
            font-weight: bold;
        }

        &__subtitle {
            text-align: center;
            font-size: 1.5rem;
        }

        &__description {
            margin-top: 1.5rem;
            margin-bottom: .75rem;
            opacity: .5;
            font-style: italic;
            width: 70%;
            max-width: 260px;
            hyphens: auto;
            line-height: 1.5rem;
        }

        &__price {
            color: #fff;
            background-color: $color__primary;
            border-radius: .5rem;
            padding: .625rem 1rem .375rem;
            font-size: 1.125rem;
        }

        &__shipping {
            font-size: .625rem;
            opacity: .5;
            margin-top: .375rem;
            line-height: 1.5rem;
        }
    }
}

.drinks {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 1rem;
    scroll-snap-type: x mandatory;
    position: relative;

    &__link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        z-index: 3;
    }

    &__modal {
        background: rgba(#fff, .8);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 2rem;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease-out;

        &--show {
            opacity: 1;
            visibility: visible;
        }

        @media(max-width: 500px) {
            padding: 1rem;
        }

        @media(max-width: 800px) {
            align-items: flex-start;
        }

        &__close {
            appearance: none;
            border: 0;
            background-color: #fff;
            border-radius: 100%;
            font-family: sans-serif;
            font-weight: 300;
            transform: rotate(45deg);
            color: $color__primary;
            font-size: 2rem;
            width: 2rem;
            height: 2rem;
            position: absolute;
            top: 1rem;
            right: 1rem;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__content {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            max-width: 1000px;
            position: relative;
            max-height: 600px;

            @media(max-width: 800px) {
                flex-direction: column;
                max-height: none;
            }
        }

        &__wrapper {
            background-color: $color__primary;
            border-radius: 1rem;
            flex-basis: 150%;
            color: #fff;
            padding: 3rem;

            @media(min-width: 800px) {
                order: -1;
            }

            @media(max-width: 500px) {
                padding: 2rem;
            }
        }

        &__image {
            border-radius: 1rem;
            display: flex;
            min-height: 400px;
            overflow: hidden;
            align-items: stretch;

            img {
                width: 100%;
                object-fit: cover;
                object-position: center top;
            }

            @media(max-width: 400px) {
                height: 100vw;
                min-height: 0;
            }
        }

        &__title {
            font-weight: 600;
            font-size: 3.75rem;
            margin: 0 0 1.5rem;
            hyphens: none;
    
            @media(max-width: 500px) {
                font-size: 2.5rem;
            }
        }

        &__subtitle {
            font-size: 1.625rem;

            @media(max-width: 500px) {
                font-size: 1.25rem;
            } 
        }

        &__text {
            line-height: 1.5rem;
        }
    }

    &__item {
        aspect-ratio: .9;
        height: 40vh;
        max-height: 600px;
        border-radius: 1rem;
        overflow: hidden;
        flex-shrink: 0;
        flex-grow: 0;
        scroll-snap-align: center;
        position: relative;

        $item: #{&};

        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center bottom;
        }

        &__name {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            color: #fff;
            font-weight: bold;
            font-size: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            background-color: rgba(#fff, .55);
            opacity: 0;
            transition: all .3s ease-out;

            #{$item}:hover & {
                opacity: 1;
            }
        }
    }
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    a {
        color: inherit;
        transition: opacity .2s ease-out;

        &:hover {
            opacity: .5;
        }
    }

    @media(max-width: 800px) {
        margin-top: 0rem;
    }

    &__label {
        border-bottom: 1px solid currentColor;
        display: flex;
        flex-direction: column-reverse;
        padding-top: .75rem;

        $inline: '';
        $checkbox: '';

        &--inline {
            $inline: #{&};
            display: grid;
            grid-template-columns: 60px auto;
            gap: 1rem;
            border-bottom: 1px solid transparent;
            position: relative;

            &::after {
                content: '';
                width: 60px;
                height: 1px;
                position: absolute;
                top: 100%;
                left: 0;
                background-color: currentColor;
            }
        }

        &--checkbox {
            $checkbox: #{&};
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 1rem;
            border: none;
            position: relative;
            align-items: center;
        }

        &__text {
            font-size: 1.125rem;
            line-height: 1.25rem;
            font-weight: 300;
            transform-origin: left bottom;
            transition: all .3s ease-out;

            #{$checkbox} & {
                font-size: 1rem;
            }
        }

        input, textarea {
            border: 0;
            appearance: none;
            width: 100%;
            font-size: 1.125rem;
            line-height: 1.5rem;
            background: transparent;
            color: currentColor;
            font-family: "HelveticaNow", "Helvetica Neue", Helvetica, Arial, sans-serif;

            &:focus {
                outline: 0;

                & + * {
                    transform: scale(.875) translateY(-50%);

                    @at-root #{$inline}#{&} {
                        transform: none;
                    }
                    @at-root #{$checkbox}#{&} {
                        transform: none;
                    }
                }
            }
            
            &[type="checkbox"] {
                border: 1px solid currentColor;
                width: 1.5rem;
                height: 1.5rem;
                position: relative;
                cursor: pointer;

                &:checked::after {
                    content: '';
                    width: 1rem;
                    height: 1px;
                    background-color: currentColor;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(45deg) ;
                }
                &:checked::before {
                    content: '';
                    width: 1rem;
                    height: 1px;
                    background-color: currentColor;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(-45deg) ;
                }
            }
        }

        textarea {
            resize: vertical;
        }
    }

    input[type="submit"] {
        width: auto;
        margin-left: auto;
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-weight: 300;
        appearance: none;
        border: 0;
        color: #fff;
        background: $color__primary;
        border-radius: .5rem;
        cursor: pointer;
        font-family: $font__main;
        padding: .625rem 1rem .375rem;
    }

    &__success {
        opacity: 0;
        display: none;
        transition: all .3s ease-in-out;
        color: #0c0;
        background: #efe;
        padding: 1.5rem 1rem;
        text-align: center;

        &--show {
            display: block;
            opacity: 1;
        }
    }

    &__error {
        opacity: 0;
        display: none;
        transition: all .3s ease-in-out;
        color: #c00;
        background: #fee;
        padding: 1.5rem 1rem;
        text-align: center;

        &--show {
            display: block;
            opacity: 1;
        }
    }

    &__submit {
        position: relative;
        display: inline-block;
        align-self: center;

        &::after {
            content: '';
            display: block;
            width: 1rem;
            height: 1rem;
            border: 4px solid $color__primary;
            border-top-color: transparent;
            border-radius: 100%;
            position: absolute;
            top: 50%;
            right: -2rem;
            margin-top: -.5rem;
            animation: progress .5s both infinite linear;
            transition: opacity .3s ease-in-out;
            opacity: 0;
        }

        &--progress {
            &::after {
                opacity: 1;
            }
        }
    }

    &__mandatory {
        text-align: center;
        align-self: center;
        margin-top: -.75rem;
    }
}

.footer {
    background: $color__primary;
    color: #fff;
    margin-top: 6rem;
    padding: 3rem 0;

    .content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-direction: row;

        @media(max-width: 600px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 3rem;
        }
    }

    a {
        color: inherit;
        transition: all .3s ease-out;
        text-decoration: none;

        &:hover {
            opacity: .6;
        }
    }

    &__menu {
        display: flex;
        flex-direction: column;
        gap: .75rem;
        margin: 0;
        padding: 0;
        list-style: none;
        width: 200px;
    }
    &__logo {
        display: flex;
        align-items: center;
        height: auto;

        img {
            width: 150px;
            height: 110px;
            object-fit: cover;
            filter: #{'invert()'};
        }
    }
    &__address {
        width: 205px;
        text-align: right;
    }
}

